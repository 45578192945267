<template>
  <default-layout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>

    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-4"></v-divider>
      <div class="my-2">
        <p
          v-if="showNotFound == true"
          class="text-center py-6 text-warning"
          style="color: #ff6f00"
        >
          <v-icon size="100" color="#FF6F00">mdi-magnify</v-icon> <br />
          <span style="font-size: 1.5em"
            >oopppsss.. <br />buku tidak ketemu..</span
          ><br />
          <br />
          <br />
          <br />
          <v-btn
            color="#ff6f00"
            dark
            class="text-white"
            @click="$refs.myRightBar.show()"
          >
            <v-icon>mdi-magnify</v-icon> Cari lagi disini...
          </v-btn>
        </p>
        <v-card
          class="my-2"
          outlined
          elevation="4"
          v-for="(announcement, i) in announcements"
          :key="i"
          @click="readAnnouncement(announcement.id)"
        >
          <v-card-title v-text="announcement.title"></v-card-title>
          <v-card-subtitle v-text="announcement.publish_date"></v-card-subtitle>
          <v-card-text v-text="announcement.short_body"></v-card-text>
        </v-card>
      </div>
      <div class="my-2">
        <v-row dense>
          <v-col class="d-flex justify-center my-4">
            <v-btn v-if="found == true && foundAnn == limit" @click="loadMore"
              >Load more..</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-container>
    <RightBar ref="myRightBar" title="Filter">
      <v-text-field
        clearable
        v-model="search"
        placeholder="Cari"
        label="Cari"
      ></v-text-field>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="setFilter">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RightBar from "@/layouts/RightBar.vue";
export default {
  components: { DefaultLayout, RightBar },
  data: () => ({
    announcements: [],
    showNotFound: null,
    page: 1,
    found: null,
    foundAnn: null,
    limit: 0,
    search: null,
    searchText: null,
  }),
  methods: {
    getListAnnouncements() {
      this.loading = true;
      this.found = false;
      this.foundAnn = 0;
      var data = {
        params: {
          page: this.page,
          search: this.search,
        },
      };

      if (this.page == 1) {
        this.announcements = [];
      }
      this.$axios.get("announcement/list", data).then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.limit = res.data.data.limit;
          this.foundAnn = res.data.data.found_announcements;
          let _this = this;
          if (res.data.data.announcements.length > 0) {
            for (var i = 0; i < res.data.data.announcements.length; i++) {
              _this.announcements.push(res.data.data.announcements[i]);
            }
            this.found = true;
          } else {
            this.found = false;
          }
          this.loading = false;

          if (this.page == 1 && this.announcements.length == 0) {
            this.showNotFound = true;
          } else {
            this.showNotFound = false;
          }
        } else {
          this.announcements = [];
        }
      });
      this.loading = false;
    },
    loadMore() {
      this.page = this.page + 1;
      this.getListAnnouncements();
    },
    setFilter() {
      try {
        if (this.$route.query.search != this.search) {
          var query = {
            search: this.search,
          };
          this.$router
            .replace({ query: query })
            .finally(() => {})
            .catch(() => {});
          this.getListAnnouncements();
        }
      } catch (error) {
        console.log(error);
      }

      this.$refs.myRightBar.hide();
    },
    resetFilter() {
      try {
        this.search = "";
        this.setFilter();
      } catch (error) {
        console.log(error);
      }
      this.$refs.myRightBar.hide();
    },
    readAnnouncement(id) {
      this.$router.push("/announcement/read/" + id);
    },
  },
  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.search = this.$route.query.search;
    this.getListAnnouncements();
    this.setPageTitle("Pengumuman", "mdi-bullhorn");
  },
};
</script>
